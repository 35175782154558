import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';
import { AppContext, IAppContext } from '../../context/context';
import InViewMonitor from 'react-inview-monitor';

const MovieItem: React.FunctionComponent<any> = (props) => {
  const context = useContext<Partial<IAppContext>>(AppContext);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      rel: 0,
      playsinline: 1,
      start: props.data.start,
      end: props.data.end,
      mute: 1
    }
  };

  return (
    <div className="util__movie-item">
      <div className="util__movie-item-row">
        <div className="util__movie-item-thumbnail">
          <div className="util__movie-item-thumbnail-inner">
            {!props.data.imagePC && (
              <YouTube
                videoId={props.data.id}
                opts={opts}
                onReady={(evt) => {
                  evt.target.mute();
                  evt.target.playVideo();
                }}
                onStateChange={(evt) => {
                  if (evt.data === 0) {
                    evt.target.seekTo(props.data.start);
                    evt.target.playVideo();
                  }
                }}
              />
            )}
            {props.data.imagePC && <Thumbnail data={props.data} />}
          </div>
        </div>
        <div className="util__movie-item-sub-row">
          <div className="util__movie-item-column ff-univ">
            {props.data.title && (
              <p
                className="util__movie-item-title ff-univ-bold"
                dangerouslySetInnerHTML={{ __html: props.data.title.split('\r\n').join('<br>') }}></p>
            )}
            {props.data.category && <p className="util__movie-item-category">{props.data.category}</p>}
          </div>

          <InViewMonitor classNameInView="active" intoViewMargin={`-100px 0px -100px 0px`}>
            <p
              className="util__movie-item-play"
              onClick={() => {
                context.toggleMovieModal(props.data.id, true);
              }}>
              <span />
            </p>
          </InViewMonitor>
        </div>
      </div>
    </div>
  );
};
export default MovieItem;

const Thumbnail: React.FunctionComponent = ({ data }) => {
  const isSP: boolean = useMediaQuery({ query: `(max-width: 414px)` });

  let backgroundPath = '';
  if (isSP) {
    backgroundPath = data.imageSP ? data.imageSP.mediaItemUrl : '';
  } else {
    backgroundPath = data.imagePC ? data.imagePC.mediaItemUrl : '';
  }

  return (
    <div
      className="util__movie-item-thumbnail"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${backgroundPath})`,
        backgroundSize: 'cover'
      }}></div>
  );
};
