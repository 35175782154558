import React, { useContext, useEffect, useMemo } from 'react';

import { AppContext, ThemeColor } from '../context/context';
import ArtistDetailContent from '../components/artists/detail/content';
import RelatedItems from '../components/utils/related-items';
import PageMover, { PAGE_MOVER_MODE } from '../components/utils/page-mover';
import MovieItem from '../components/utils/movie-item';
import { Helmet } from 'react-helmet';

import '../css/artists_detail.css';
import { graphql } from 'gatsby';
import { PATH } from '../helper/config';

const ArtistDetail: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);
  const localData = data.main;
  const artistData = localData.artist;

  useEffect(() => {
    if (pageContext.locale === 'ja') {
      if (data.main.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.ARTISTS_DETAIL}${data.main.databaseId}`,
            en: `/en${PATH.ARTISTS_DETAIL}${data.main.translated[0].databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.ARTISTS_DETAIL}${data.main.databaseId}`,
            en: `/en${PATH.ARTISTS}`
          },
          pageContext.locale
        );
      }
    } else {
      if (data.main.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.ARTISTS_DETAIL}${data.main.translated[0].databaseId}`,
            en: `/en${PATH.ARTISTS_DETAIL}${data.main.databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.ARTISTS}`,
            en: `/en${PATH.ARTISTS_DETAIL}${data.main.databaseId}`
          },
          pageContext.locale
        );
      }
    }
  }, []);

  const createNavigation = (): any => {
    let detectID: number = 0;
    for (let i: number = 0; i < data.navigation.edges.length; i++) {
      if (data.navigation.edges[i].node.databaseId === pageContext.databaseId) {
        detectID = i;
      }
    }
    let prevID: number = detectID - 1;
    let nextID: number = detectID + 1;
    if (prevID < 0) prevID = data.navigation.edges.length - 1;
    if (nextID >= data.navigation.edges.length) nextID = 0;

    return {
      prev: { ...data.navigation.edges[prevID].node },
      next: { ...data.navigation.edges[nextID].node }
    };
  };

  const navigation: any = useMemo(() => createNavigation(), []);

  useEffect(() => {
    context.setTheme(ThemeColor.GRAY);
    return () => {
      context.setTheme(ThemeColor.DEFAULT);
    };
  }, []);
  return (
    <section className="artist-detail util__page">
      <Helmet>
        <title>{artistData.name} | POST-FAKE</title>
        <meta name="Description" content={artistData.profile.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')} />
        <meta property="og:description" content={artistData.profile.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')} />
        <meta property="og:title" content={`${artistData.name} | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.ARTISTS_DETAIL}${data.main.databaseId}`} />
        {artistData.image && artistData.image.mediaItemUrl && <meta property="og:image" content={artistData.image.mediaItemUrl} />}
        <meta name="twitter:site" content={`${artistData.name} | POST-FAKE`} />
        <meta name="twitter:title" content={`${artistData.name} | POST-FAKE`} />
        <meta name="twitter:description" content={artistData.profile.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')} />
      </Helmet>

      <ArtistDetailContent data={artistData} />

      {artistData.movie && artistData.movie.youtubeid && artistData.movie.youtubeid.length && (
        <MovieItem
          data={{
            id: artistData.movie.youtubeid,
            title: artistData.movie.title,
            category: artistData.movie.subtitle,
            start: Number(artistData.movie.starttime) || 0,
            end: Number(artistData.movie.endtime) || 0,
            imagePC: data.pcImage,
            imageSP: data.spImage
          }}
        />
      )}
      {localData.gqlRelatedProductsAcf.products && <RelatedItems data={localData.gqlRelatedProductsAcf.products} />}
      <PageMover navigation={navigation} mode={PAGE_MOVER_MODE.ARTIST} />
    </section>
  );
};
export default ArtistDetail;

export const query = graphql`
  query artistDetailQuery($databaseId: Int!, $locale: String!) {
    main: wpGqlArtistItem(databaseId: { eq: $databaseId }) {
      databaseId
      translated {
        databaseId
      }
      artist {
        name
        nameEnglish
        position
        profile
        movie {
          youtubeid
          title
          subtitle
          starttime
          endtime
          pcImage {
            mediaItemUrl
          }
          spImage {
            mediaItemUrl
          }
        }
        image {
          mediaItemUrl
          mediaDetails {
            height
            width
          }
        }
        arts {
          ... on WpGqlArtistItem_Artist_Arts_Block1column {
            fieldGroupName
            caption1
            image1 {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
          ... on WpGqlArtistItem_Artist_Arts_Block2column {
            fieldGroupName
            caption1
            caption2
            image1 {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
            image2 {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
        }
        posts {
          ... on WpGqlJournalItem {
            title
            databaseId
            date
            gqlJournalCategories {
              nodes {
                name
              }
            }
            optionJournalFields {
              title
              thumbnail {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
      gqlRelatedProductsAcf {
        products {
          name
          price
          title
          url
          image {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
    navigation: allWpGqlArtistItem(filter: { locale: { locale: { eq: $locale } } }) {
      edges {
        node {
          databaseId
          artist {
            name
            nameEnglish
            position
          }
        }
      }
    }
  }
`;
