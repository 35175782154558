import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { AppContext } from '../../../context/context';
import { PATH } from '../../../helper/config';
import Util from '../../../templates/utils/util';
import LazyImage from '../../utils/lazy-image';

const ArtistDetailContent: React.FunctionComponent<{ data: any }> = ({ data }) => {
  const context = useContext(AppContext);
  return (
    <div className="artist-detail__content">
      {data.nameEnglish && (
        <h1
          className="artist-detail__content-caption ff-univ-bold"
          dangerouslySetInnerHTML={{ __html: data.nameEnglish.split('\r\n').join('<br>') }}></h1>
      )}
      <div className="artist-detail__content-role ff-mono">/ {data.position}</div>

      <div className="artist-detail__content-row">
        <figure className="artist-detail__content-figure">{data.image && <LazyImage data={data.image} />}</figure>

        <div className="artist-detail__content-column">
          <dl className="artist-detail__definition-list">
            <dt className="artist-detail__definition-list-term">{data.name}</dt>
            <dd className="artist-detail__definition-list-description" dangerouslySetInnerHTML={{ __html: data.profile }}></dd>
          </dl>

          {data.posts && data.posts.length && (
            <div className="artist-detail__related">
              <h3 className="artist-detail__related-caption ff-univ-bold">RELATED JOURNAL</h3>
              <ul className="artist-detail__related-list">
                {data.posts &&
                  data.posts.map((elem: any, i: number) => {
                    return (
                      <li className="artist-detail__related-list-item" key={`artist-detail__related-list-item-${elem.databaseId}-${i}`}>
                        <Link to={`${context.localePath}${PATH.JOURNAL_DETAIL}${elem.databaseId}`}>
                          <figure className="artist-detail__related-list-item-figure">
                            {elem.optionJournalFields.thumbnail && <LazyImage className="fit" data={elem.optionJournalFields.thumbnail} />}
                          </figure>
                          <div className="artist-detail__related-list-item-column">
                            <p className="artist-detail__related-list-item-column-date ff-mono">{Util.GET_DATE(elem.date).ja}</p>
                            <p className="artist-detail__related-list-item-column-title">{elem.optionJournalFields.title}</p>

                            {elem.gqlJournalCategories && elem.gqlJournalCategories.nodes && elem.gqlJournalCategories.nodes.length && (
                              <div className="artist-detail__related-list-item-column-category ff-mono">
                                #{elem.gqlJournalCategories.nodes[0].name}
                              </div>
                            )}
                          </div>
                          <i className="glyphs-arrow-right"></i>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      </div>

      {data.arts &&
        data.arts.map((elem: any, i: number) => {
          return <ArtistArts data={elem} key={`artist-detail-arts-${i}`} />;
        })}
    </div>
  );
};
export default ArtistDetailContent;

const ArtistArts: React.FunctionComponent<any> = ({ data }) => {
  if (!data.image1) return <></>;
  if (data.fieldGroupName === 'GqlArtistItem_Artist_Arts_Block1column') {
    return (
      <div className={`artist-detail__art`}>
        <figure>
          <span>
            <LazyImage data={data.image1} />
          </span>
          {data.caption1 && <figcaption className="ff-mono">{data.caption1}</figcaption>}
        </figure>
      </div>
    );
  } else {
    return (
      <div className="artist-detail__art artist-detail__art--2column">
        <figure>
          <span>
            <LazyImage data={data.image1} />
          </span>
          {data.caption1 && <figcaption className="ff-mono">{data.caption1}</figcaption>}
        </figure>
        <figure>
          <span>
            <LazyImage data={data.image2} />
          </span>
          {data.caption2 && <figcaption className="ff-mono">{data.caption2}</figcaption>}
        </figure>
      </div>
    );
  }
};
